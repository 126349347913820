import React from 'react';
import {Layout} from '../modules/layout/containers/Layout';
import {graphql} from 'gatsby';
import SEO from '../modules/layout/containers/SEO';
import {SimplePage} from '../modules/uxui/SimplePage';
import _get from 'lodash/get';
import {useSiteMetadata} from '../modules/common/hooks/useSiteMetadata';
import {computeSEOMeta} from '../modules/core/util/SEOUtil';

interface Props {
    location: any;
    data: {
        wpgraphql: {
            post: any;
        };
    };
}

export const BlogPostPageTpl: React.FC<Props> = ({data, location}) => {
    const rawPost = _get(data, 'wpgraphql.post');
    const siteMetadata = useSiteMetadata();
    const {title, metadata} = computeSEOMeta(siteMetadata, rawPost.seo);
    return (
        <Layout location={location}>
            <SEO title={title} meta={metadata} />
            <SimplePage title={rawPost.title} htmlContent={rawPost.content} />
        </Layout>
    );
};

export const query = graphql`
    query($id: ID!) {
        wpgraphql {
            post(id: $id) {
                id
                author {
                    firstName
                    lastName
                }
                databaseId
                date
                dateGmt
                link
                slug
                title
                status
                guid
                modified
                uri
                content
                seo {
                    ...WPSEOFragment
                }
            }
        }
    }
`;

export default BlogPostPageTpl;
